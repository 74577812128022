exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-ammanford-js": () => import("./../../../src/pages/locations/ammanford.js" /* webpackChunkName: "component---src-pages-locations-ammanford-js" */),
  "component---src-pages-locations-gloucester-js": () => import("./../../../src/pages/locations/gloucester.js" /* webpackChunkName: "component---src-pages-locations-gloucester-js" */),
  "component---src-pages-locations-index-js": () => import("./../../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-locations-stoke-js": () => import("./../../../src/pages/locations/stoke.js" /* webpackChunkName: "component---src-pages-locations-stoke-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/categoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/productTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

